.footer {
  display: flex;
  flex-direction: column;
}
.footer p {
  color: var(--default-color);
}
.pagination-area {
  display: flex;
  color: var(--text-secondary-color);
  font-size: 13px;
  font-weight: normal !important;
}
.pagination-area label {
  font-weight: normal;
}
.pagination-area select {
  margin: 5px;
  background-color: var(--primary-background-color);
  color: #fff;
}
.pagination-area .rows-dropdown {
  width: 25%;
  padding-right: 15px;
  padding-left: 15px;
}
.pagination-area .pagination-buttons {
  width: 75%;
  padding-right: 15px;
  padding-left: 15px;
}
.pagination-area .form-group {
  margin-bottom: 15px;
}
.rows-per-page label.wrapperLabel {
  display: inline-table !important;
  margin: 0 4px;
}
.rows-per-page label.wrapperLabel select {
  margin: 0px 4px 0px 4px;
  width: 42px;
}
.page-buttons {
  margin: 0;
  text-align: right;
  font-weight: normal;
}
.page-buttons ul.pagination-control {
  margin: 0;
}
.page-buttons ul.pagination-control li {
  display: table-cell;
  padding: 5px 2px;
}
.page-buttons ul.pagination-control li button {
  padding: 4px 8px;
  background-color: var(--primary-background-color);
  border-color: var(--ui-gray);
  color: var(--ui-gray-darkest);
  color: #fff;
  text-decoration: none;
}
.page-buttons ul.pagination-control li button:hover:enabled {
  color: var(--active-color);
}
.page-buttons ul.pagination-control li .active button {
  background-color: var(--ui-gray);
  border-color: #ddd;
  color: #fff;
}
